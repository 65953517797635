/* global $*/
$(function () {
  $(".sliders").slick({
    // variableWidth: true,
    // adaptiveHeight: true,
    // centerMode: true,
    focusOnSelect: true,
    // arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    // adaptiveHeight: true
  });
});